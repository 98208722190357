<template>
  <div class="home">
    <div class="main-slider">
      <video autoplay loop muted playsinline class="background-video">
        <source src="/assets/video/background_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="image-slider">
        <div class="title">Building Visions,<br />Shaping The Future</div>
        <div class="description">Experienced & Reliable Builders.</div>
      </div>
    </div>
    <div class="about fos">
      <div class="info">
        <span class="pre-title">About our company</span>
        <span class="title"
        >We provide professional solutions to deliver safe & efficient
          projects</span
        >
        <span class="description"
        >This is the space to introduce visitors to the business or brand.
          Briefly explain who's behind it, what it does and what makes it
          unique. Share its core values and what this site has to offer.</span
        >
      </div>
      <img src="/assets/images/office.webp" alt="about" />
    </div>
    <div class="featured-projects">
      <div class="info">
        <span class="pre-title">Featured Projects</span>
        <span class="title">We Build Projects That Last</span>
      </div>
      <div class="slider">
        <Carousel
            :breakpoints="breakpoints"
            :wrap-around="true"
            :transition="700"
        >
          <Slide v-for="(value, index) in projects.slice(0, 10)" :key="index">
            <div
                class="carousel__item"
                @click="redirect(`project/${value.name}`)"
            >
              <img
                  :src="`/assets/projects/${value.name}/${value.main_photo}`"
                  alt="Project Image"
              />
            </div>
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
    <div class="start-project fos">
      <div class="text">
        <span class="title">Start Your Construction Project Today</span>
        <span class="description"
        >Get in touch with our team to discuss your construction needs and
          kickstart your project.</span
        >
      </div>
      <a class="button" href="/contact">Contact Us</a>
    </div>
    <div class="faq fos">
      <div class="info">
        <span class="pre-title">You've got questions, we've got answers</span>
        <span class="title">FAQ Section</span>
        <span class="description"
        >We have all the answers for your questions. Find out everything you
          need to know about working with Create Constructions.</span
        >
      </div>
      <div class="questions">
        <div class="question">
          <span class="question-text">What services do you offer?</span>
          <span class="answer-text"
          >We offer a comprehensive range of construction services, including
            new builds, renovations, and large-scale developments.</span
          >
        </div>
        <div class="question">
          <span class="question-text">How do you ensure project quality?</span>
          <span class="answer-text"
          >Our process is rooted in meticulous planning, employing skilled
            tradespeople, and using high-quality materials for excellence in
            construction.</span
          >
        </div>
        <div class="question">
          <span class="question-text">Can you manage my project?</span>
          <span class="answer-text"
          >Absolutely! Our team excels at project management, ensuring
            everything runs smoothly from inception to completion.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { useFadeInOnScroll } from '../../../../../Documents/GitHub/create-constructions/src/composables/useFadeInOnScroll';
import { Carousel,Pagination, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'HomeView',
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  props: [
    'projects'
  ],
  data() {
    return {
      breakpoints: {
        700: {
          itemsToShow: 1
        },
        1024: {
          itemsToShow: 3
        },
      },
      projects: [] as any,
      loading: true,
    };
  },
  methods: {
    redirect(name: string) {
      window.location.href = `/${name}`;
    },
  },
  setup() {
    useFadeInOnScroll();
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.projects = to.params.projects;
      vm.loading = false;
    });
  },
  watch: {
    '$route' (to, from) {
      this.projects = to.params.projects;
    }
  },
});
</script>

<style lang="scss" scoped>
.home {
  .main-slider {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 58rem;
    margin-bottom: 50px;

    .background-video {
      width: 100%;
      height: 105%;
      position: absolute;
      object-fit: cover;
    }

    .image-slider {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: white;
      margin-left: 3rem;

      .title {
        position: absolute;
        font-size: 7rem;
      }

      .description {
        position: absolute;
        margin-bottom: 3rem;
        font-size: 2rem;
        margin-top: 29rem;
      }
    }

    .slide-down {
      cursor: pointer;
    }

    .slide-down::before {
      content: "";
      position: absolute;
      border: 3px solid #ef6c60;
      width: 30px;
      height: 55px;
      bottom: 8%;
      border-radius: 20px;
      left: 49%;
    }

    .bullet::before {
      content: "";
      width: 17px;
      height: 17px;
      opacity: 0;
      position: absolute;
      background-color: #ef6c60;
      bottom: 11%;
      border-radius: 50%;
      left: 58.86rem;
      animation: slideInFromLeft 2s linear infinite;
    }
  }

  .about {
    width: 85%;
    display: flex;
    margin: 17rem auto 0;
    justify-content: space-between;
    align-items: center;

    .info {
      .pre-title {
        text-transform: uppercase;
      }

      .title {
        width: 90%;
        font-size: 50px;
        display: flex;
        align-items: center;
        color: black;
        justify-content: space-between;
      }

      .description {
        font-size: 18px;
        display: flex;
        width: 66%;
        margin: 2rem 0 5rem;
      }

      .button {
        padding: 10px;
        border: 1px solid red;
        background-color: white;
        border-radius: 3px;
        color: red;
        text-transform: uppercase;
        font-size: 17px;
        text-decoration: none;
        transition: all 0.2s ease;

        .arrow {
          transform: rotate(-315deg);
          transition: all 0.2s ease;
        }
      }

      .button:hover {
        border: 1px solid red;
        background-color: red;
        color: white;

        .arrow {
          transform: rotate(0deg);
        }
      }
    }

    img {
      width: 30rem;
      height: 30rem;
      object-fit: cover;
    }
  }

  .featured-projects {
    width: 85%;
    display: flex;
    margin: 10rem auto 0;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .info {
      margin-left: 3rem;
      width: 100%;

      .pre-title {
        text-transform: uppercase;
      }

      .title {
        width: 90%;
        font-size: 50px;
        display: flex;
        align-items: center;
        color: black;
        justify-content: space-between;
      }
    }

    .slider {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;

      .carousel__item {
        position: relative;
        width: 100%;
        height: 24rem;
        overflow: hidden;

        &::before {
          content: "";
          position: absolute;
          background-color: rgba(0, 0, 0, 0.38);
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: opacity 0.4s ease;
          z-index: 1;
        }

        &:hover {
          cursor: pointer;

          &::before {
            opacity: 1;
          }

          img {
            transform: scale(1.1);
          }
        }

        img {
          width: 100%;
          height: 24rem;
          object-fit: cover;
          border-radius: 3px;
          transition: transform 0.3s ease;
        }
      }
    }
  }

  .start-project {
    width: 75%;
    padding: 55px;
    display: flex;
    justify-content: space-between;
    background-color: #ff5144;
    align-items: center;
    transform: rotate(-2deg) !important;
    margin: 10rem auto 0;

    .text {
      display: flex;
      flex-direction: column;
      transform: rotate(2deg);

      .title {
        font-size: 40px;
      }

      .description {
        margin-top: 1rem;
        font-size: 18px;
      }
    }

    .button {
      background-color: rgba(244, 67, 54, 0.83);
      border: 1px solid rgba(244, 67, 54, 0.83);
      padding: 12px;
      color: white;
      border-radius: 3px;
      transform: rotate(2deg);
      text-decoration: none;
      transition: all 0.4s ease;
    }

    .button:hover {
      background-color: #f44336;
      border: 1px solid #f44336;
    }
  }

  .start-project::before {
    content: "";
    position: absolute;
    background-color: #ffddda;
    width: 100%;
    height: 100%;
    left: 0;
    transform: rotate(1.5deg) !important;
    z-index: -1;
  }

  .start-project:hover {
    transform: scale(1.05) rotate(-3deg) !important;
  }

  .faq {
    width: 85%;
    display: flex;
    margin: 10rem auto 0;
    justify-content: space-between;

    .info {
      .pre-title {
        text-transform: uppercase;
      }

      .title {
        width: 100%;
        font-size: 50px;
        display: flex;
        align-items: center;
        color: black;
        justify-content: space-between;
      }

      .description {
        font-size: 18px;
        display: flex;
        width: 66%;
        margin-top: 2rem;
      }
    }

    .questions {
      .question {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        .question-text {
          font-weight: 600;
        }

        .answer-text {
        }
      }
    }
  }
}

.fos {
  opacity: 0;
  transform: translateY(100px);
  transition:
      opacity 1s ease-out,
      transform 1s ease-out;
}

.fos.visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  40% {
    opacity: 1;
  }

  95% {
    opacity: 0;
    transform: translateY(+25px);
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1300px) {
  .home {
    .main-slider {
      align-items: flex-start;

      .image-slider {
        align-items: center !important;
        justify-content: center !important;
        margin: 24rem 0 0 !important;

        .title {
          font-size: 4rem !important;
          text-align: center;
        }

        .description {
          font-size: 17px !important;
          margin-top: 29rem !important;
          text-align: center !important;
        }
      }

      .slide-down::before {
        left: 46%;
      }

      .bullet::before {
        left: 13rem;
      }
    }

    .about {
      flex-direction: column-reverse;
      margin: 2rem auto !important;

      .pre-title {
        font-size: 13px;
      }
      .title {
        font-size: 27px !important;
        font-weight: 400;
      }

      .description {
        width: 100% !important;
        margin: 2rem 0 3rem !important;
      }

      img {
        width: 22rem !important;
        height: 22rem !important;
        margin-bottom: 1rem;
      }
    }

    .featured-projects {
      margin: 5rem auto 0 !important;

      .info {
        .pre-title {
          font-size: 13px;
        }

        .title {
          font-size: 38px;
        }
      }

      .slider {
        margin-top: 2rem;
      }
    }

    .start-project {
      flex-wrap: wrap;
      padding: 33px;
      margin: 5rem auto 0 !important;

      .text {
        width: 100%;

        .title {
          font-size: 27px;
        }

        .description {
          margin-top: 1rem;
          font-size: 14px;
        }
      }

      .button {
        margin-top: 1rem;
      }
    }

    .start-project:hover {
      transform: unset !important;
    }

    .faq {
      margin: 5rem auto 0 !important;
      flex-direction: column;

      .info {
        .pre-title {
          font-size: 13px;
        }

        .title {
          font-size: 45px;
        }

        .description {
          width: 100%;
          font-size: 16px !important;
          margin-top: 1rem !important;
        }
      }

      .questions {
        margin-top: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .home {
    .main-slider {
      height: 50rem !important;
    }
    .about {
      margin: 1rem auto !important;
    }

    .featured-projects {
      margin: 3rem auto 0 !important;
    }
  }
}
</style>
