import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from '@/views/ContactView.vue'
import ProjectsView from "@/views/ProjectsView.vue";
import ProjectView from "@/views/ProjectView.vue"
import ServicesView from "@/views/ServicesView.vue";
import { eventBus } from '@/eventBus';
import axios from "axios";

const fetchProjects = async (to: any, from: any, next: any) => {
  try {
    const data = await axios.get('https://createconstructions.gr/assets/php/projects.php');

    if (!data || data.status !== 200) {
      throw new Error('Network response was not ok');
    }

    to.params.projects = data.data;
    next();
  } catch (error: any) {
    console.error('Failed to fetch data:', error);

    next('/');
  }
};

const fetchProject = async (to: any, from: any, next: any) => {
  try {
    const data = await axios.get(`https://createconstructions.gr/assets/php/projects.php?name=${to.params.id}`);

    if (!data || data.status !== 200) {
      throw new Error('Network response was not ok');
    }

    to.params.project = data.data;
    next();
  } catch (error: any) {
    console.error('Failed to fetch project:', error);

    next('/');
  }
};

const fetchFavouriteProjects = async (to: any, from: any, next: any) => {
  try {
    const data = await axios.get('https://createconstructions.gr/assets/php/projects.php?favourite=1');

    if (!data || data.status !== 200) {
      throw new Error('Network response was not ok');
    }

    to.params.projects = data.data;
    next();
  } catch (error: any) {
    console.error('Failed to fetch favourite projects:', error);

    next('/');
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: fetchFavouriteProjects,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView,
    beforeEnter: fetchProjects,
  },
  {
    path: '/project/:id',
    name: 'project',
    component: ProjectView,
    beforeEnter: fetchProject,
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  eventBus.isLoading = true;
  next();
});

router.afterEach(() => {
  setTimeout(() => {
    eventBus.isLoading = false;
  }, 1500);
});

export default router;
