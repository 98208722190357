<template>
  <LoadingView />
  <nav id="navbar" v-if="!eventBus.isLoading">
    <div :class="['nav', { 'nav--open': isOpen }]">
      <div class="sbar">
        <img alt="logo" src="/assets/images/logo.webp" @click="redirect('/')" />
        <div class="button" @click="toggleNav">
          <div class="bar bar1" :class="{ active: isOpen }"></div>
          <div class="bar bar2" :class="{ active: isOpen }"></div>
          <div class="bar bar3" :class="{ active: isOpen }"></div>
        </div>
      </div>
      <div class="sidebar">
        <div class="pages">
          <a href="/home" :class="{ active: $route.name === 'home' }">Home</a>
          <a href="/projects" :class="{ active: $route.name === 'projects' }"
          >Projects</a
          >
          <a href="/services" :class="{ active: $route.name === 'services' }"
          >Services</a
          >
          <a href="/contact" :class="{ active: $route.name === 'contact' }"
          >Contact</a
          >
        </div>
      </div>
    </div>
  </nav>
  <router-view v-if="!eventBus.isLoading" :info="info" />
  <div class="footer" v-if="!eventBus.isLoading">
    <div class="f">
      <div class="info">
        <span class="title">Contact</span>
        <div class="list">
          <div>
            <font-awesome-icon icon="at" /><a
              :href="`mailto:${info.email}`"
          >{{ info.email }}</a
          >
          </div>
          <div>
            <font-awesome-icon icon="mobile-screen" /><a
              :href="`tel:${info.telephone}`"
          >{{ info.telephone }}</a
          >
          </div>
          <div>
            <font-awesome-icon icon="location-dot" /><a
              :href="info.map"
          >{{ info.location }}</a
          >
          </div>
        </div>
      </div>
      <div class="logo">
        <img alt="logo" src="/assets/images/logo.webp" @click="redirect('/')" />
        <div class="social">
          <font-awesome-icon
              @click="redirect(info.facebook)"
              icon="fa-brands fa-facebook"
          />
          <font-awesome-icon
              @click="redirect(info.instagram)"
              icon="fa-brands fa-instagram"
          />
          <font-awesome-icon
              @click="redirect(info.tiktok)"
              icon="fa-brands fa-tiktok"
          />
        </div>
      </div>
      <div class="newsletter">
        <span class="title">Newsletter</span>
        <div class="form">
          <div class="status">{{ newsletter.status }}</div>
          <label>Email</label>
          <input
              type="email"
              id="email"
              pattern=".+@example\.com"
              v-model="newsletter.email"
              size="30"
              required
          />
          <div class="button" @click="submitForm">Subscribe</div>
        </div>
      </div>
    </div>
    <span class="cp"
    >Made from&nbsp;<a
        style="text-decoration: none; color: red"
        href="https://rizopoulosdev.gr"
    >George Rizopoulos</a
    >. © Copyrights 2024</span
    >
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import axios from "axios";
import LoadingView from "@/views/LoadingView.vue";
import {eventBus} from "@/eventBus";
export default defineComponent({
  components: {
    LoadingView
  },
  data() {
    return {
      isOpen: false,
      projects: [] as any,
      info: {
        email: 'info@createconstructions.gr',
        telephone: '2842 300667',
        location: 'Filotheou A\' 75',
        map: 'https://maps.app.goo.gl/yXrazWMv469CaopT7',
        facebook: 'https://facebook.com',
        instagram: 'https://www.instagram.com/create_constructions_gr/',
        tiktok: 'https://tiktok.com'
      },
      newsletter: {
        email: '',
        status: ''
      }
    };
  },
  methods: {
    redirect(link: string) {
      window.location.href = link;
    },
    toggleNav() {
      this.isOpen = !this.isOpen;
    },
    async submitForm() {
      if (!this.validateEmail(this.newsletter.email)) {
        this.newsletter.status = '* Enter a valid Email Address!';
        return;
      }

      const req = await axios({
        url: 'https://createconstructions.gr/assets/php/subscribe.php',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          email: this.newsletter.email,
        }
      }).catch((e: any) => {console.error(e)});

      if(req && req.status === 429) {
        this.newsletter.status = 'You have been rate limited, please try again later!';
        return;
      }

      if(!req || req.status !== 200) {
        this.newsletter.status = 'Something went wrong, please try again later!';
        return;
      }

      this.newsletter = {
        email: '',
        status: ''
      };
    },
    validateEmail(email: string) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
  },
  async mounted() {
    window.onscroll = function () {
      let navbar = document.getElementById('navbar');
      if (!navbar) return;

      if (window.pageYOffset > 10) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };
  },
  setup() {
    return {
      eventBus,
    };
  },
});
</script>

<style lang="scss" scoped>
nav {
  .nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 4;
    transition: all 0.4s ease;

    .sbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.4s ease;
      padding: 7px 1rem;

      img {
        width: 17rem;
        height: auto;
        z-index: 2;
        cursor: pointer;
      }

      .button {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 2;
        transition: all 0.4s ease;

        .bar {
          transition: all 0.4s ease;
        }

        .bar::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 2px;
          background-color: black;
          border-radius: 15px;
        }

        .bar1 {
          transform-origin: top left;
        }

        .bar2 {
          opacity: 1;
        }

        .bar3 {
          transform-origin: bottom left;
        }

        .bar1.active {
          transform: rotate(45deg) translate(0px, -2px);
        }

        .bar2.active {
          opacity: 0;
        }

        .bar3.active {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .sidebar {
    height: 85px;
    width: 100%;
    position: fixed;
    left: 100%;
    background-color: #ffffff;
    overflow-x: hidden;
    z-index: 1;
    transition: all 0.4s ease;
    display: flex;
    justify-content: flex-end;

    .pages {
      width: 28%;
      display: flex;
      text-transform: uppercase;
      align-items: center;
      justify-content: space-evenly;
      margin-right: 70px;

      a {
        text-decoration: none;
        color: #b9babc;
        font-size: 17px;
        cursor: pointer;
        transition: all 0.4s ease;
      }

      a:hover {
        color: black;
      }

      .active {
        color: black;
      }
    }
  }

  .nav--open {
    .sidebar {
      left: 0 !important;
    }
  }
}

.scrolled {
  .sbar {
    margin: 0 !important;
    box-shadow: 0 0 13px -10px #000000;
    background-color: rgb(255 255 255 / 85%);
  }

  .sidebar {
    top: 0 !important;
  }
}

.footer {
  width: 100%;
  background-color: #2f2e2e;
  padding: 3rem 0 1rem;
  color: white;
  margin-top: 5rem;

  .f {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .info {
      .title {
        font-size: 30px;
      }

      .list {
        height: 6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 1rem;

        div {
          display: flex;
          font-size: 18px;
          margin-top: 8px;

          a {
            color: white;
            text-decoration: none;
          }

          svg {
            font-size: 22px;
            margin-right: 5px;
          }
        }
      }
    }

    .logo {
      img {
        width: 26rem;
        height: auto;
      }

      .social {
        margin: 5px auto 0;
        width: 75%;
        display: flex;
        font-size: 35px;
        justify-content: space-evenly;

        svg {
          cursor: pointer;
          transition: all 0.4s ease;
        }

        svg:hover {
          color: #f44336;
        }
      }
    }

    .newsletter {
      .title {
        font-size: 30px;
      }

      .form {
        display: flex;
        flex-direction: column;

        label {
          font-size: 18px;
          margin-top: 1rem;
        }

        input {
          border: none;
          border-bottom: 1px solid white;
          background-color: rgba(255, 255, 255, 0);
          margin-top: 10px;
          color: white;
          font-size: 16px;
          padding: 4px;
          border-radius: 2px;
        }

        input:focus {
          outline: none;
        }

        .button {
          margin-top: 1rem;
          border: 2px solid #f44336;
          width: 5rem;
          padding: 10px 10px;
          text-align: center;
          border-radius: 2px;
          cursor: pointer;
          transition: all 0.4s ease;
        }

        .button:hover {
          background-color: #f44336;
        }
      }
    }
  }

  .cp {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 1024px) {
  nav {
    .nav {
      width: 100%;
      height: 5%;
      align-items: flex-start;
      padding: 0 !important;

      .sbar {
        margin-top: 1rem;

        img {
          width: 13rem;
        }
      }

      .sidebar {
        height: 100%;

        .pages {
          width: 100%;
          display: flex;
          text-transform: uppercase;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          margin: 9rem auto;

          a {
            font-size: 3rem;
          }
        }
      }
    }

    .nav--open .sidebar {
      background-color: #2f2e2e;
    }
  }

  .footer {
    margin: 0;

    .f {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-around;
      height: 40rem;

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .logo {
        margin: 5rem 0;

        img {
          width: 23rem !important;
        }
      }

      .newsletter {
        text-align: center;

        .form {
          text-align: start;
        }
      }
    }

    .cp {
      margin-top: 5rem;
    }
  }
}

@media only screen and (max-width: 450px) {
  .cp {
    font-size: 13px;
  }
}
</style>
