<template>
  <transition name="fade">
    <div class="screen" v-if="isLoading">
      <div class="anim"></div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { eventBus } from '@/eventBus';

export default defineComponent({
  setup() {
    const isLoading = computed(() => eventBus.isLoading);
    return {
      isLoading,
    };
  },
});
</script>

<style scoped lang="scss">
.screen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-color: #2f2e2e;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; // Ensure it's on top of everything
}

.anim {
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid #ffffff;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;

  &:before,
  &:after {
    content: "";
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid #ffffff;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
  }

  &:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid #ffffff;
    animation: none;
  }
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition:
      opacity 0.5s ease,
      visibility 0.5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  visibility: hidden;
}
</style>
