import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleLeft, faAngleRight, faMinus, faAt, faMobileScreen, faLocationDot, faArrowRight, faHelmetSafety, faPenRuler, faPaste, faSignHanging, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'

library.add(faAngleLeft, faAngleRight, faMinus, faFacebook, faInstagram, faTiktok, faAt, faMobileScreen, faLocationDot, faArrowRight, faHelmetSafety, faPenRuler, faPaste, faSignHanging, faUserTie);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router);

app.mount('#app');
