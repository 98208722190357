<template>
  <div class="projects">
    <div class="header">
      <div class="pre-title">Our Projects</div>
      <div class="title">We Build Projects<br />That Last</div>
    </div>
    <div class="list">
      <div v-for="(layer, index) in getListArray()" :key="index" class="layer">
        <div v-if="index % 2 == 0" class="sub left">
          <div
              v-if="layer[0]"
              class="project horizontal"
              @click="redirect(layer[0].name)"
          >
            <img
                :alt="layer[0].title"
                :src="`/assets/projects/${layer[0].name}/${layer[0].main_photo}`"
            />
            <div class="info">
              <div class="title">{{ layer[0].title }}</div>
              <div class="separator"></div>
              <div class="location">{{ layer[0].city }}</div>
            </div>
          </div>

          <div
              v-if="layer[1]"
              :class="['project', 'vertical', !(layer[5] || layer[6]) ? 'v-single' : '']"
              @click="redirect(layer[1].name)"
          >
            <img
                :alt="layer[1].title"
                :src="`/assets/projects/${layer[1].name}/${layer[1].main_photo}`"
            />
            <div class="info">
              <div class="title">{{ layer[1].title }}</div>
              <div class="separator"></div>
              <div class="location">{{ layer[1].city }}</div>
            </div>
          </div>

          <div
              v-if="layer[2] || layer[3]"
              :class="['normal', layer[2] && layer[3] ? 'double' : 'single']"
          >
            <div
                v-if="layer[2]"
                class="project"
                @click="redirect(layer[2].name)"
            >
              <img
                  :alt="layer[2].title"
                  :src="`/assets/projects/${layer[2].name}/${layer[2].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[2].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[2].city }}</div>
              </div>
            </div>
            <div
                v-if="layer[3]"
                class="project"
                @click="redirect(layer[3].name)"
            >
              <img
                  :alt="layer[3].title"
                  :src="`/assets/projects/${layer[3].name}/${layer[3].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[3].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[3].city }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="index % 2 == 0" class="sub right">
          <div
              v-if="layer[4]"
              class="project square"
              @click="redirect(layer[4].name)"
          >
            <img
                :alt="layer[4].title"
                :src="`/assets/projects/${layer[4].name}/${layer[4].main_photo}`"
            />
            <div class="info">
              <div class="title">{{ layer[4].title }}</div>
              <div class="separator"></div>
              <div class="location">{{ layer[4].city }}</div>
            </div>
          </div>

          <div
              v-if="layer[5] || layer[6]"
              :class="['normal', layer[5] && layer[6] ? 'double' : 'single']"
          >
            <div
                v-if="layer[5]"
                class="project"
                @click="redirect(layer[5].name)"
            >
              <img
                  :alt="layer[5].title"
                  :src="`/assets/projects/${layer[5].name}/${layer[5].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[5].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[5].city }}</div>
              </div>
            </div>
            <div
                v-if="layer[6]"
                class="project"
                @click="redirect(layer[6].name)"
            >
              <img
                  :alt="layer[6].title"
                  :src="`/assets/projects/${layer[6].name}/${layer[6].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[6].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[6].city }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="index % 2 == 1" class="sub left switched">
          <div
              v-if="layer[0]"
              class="project square"
              @click="redirect(layer[0].name)"
          >
            <img
                :alt="layer[0].title"
                :src="`/assets/projects/${layer[0].name}/${layer[0].main_photo}`"
            />
            <div class="info">
              <div class="title">{{ layer[0].title }}</div>
              <div class="separator"></div>
              <div class="location">{{ layer[0].city }}</div>
            </div>
          </div>

          <div
              v-if="layer[1] || layer[2]"
              :class="['normal', layer[1] && layer[2] ? 'double' : 'single']"
          >
            <div
                v-if="layer[1]"
                class="project"
                @click="redirect(layer[1].name)"
            >
              <img
                  :alt="layer[1].title"
                  :src="`/assets/projects/${layer[1].name}/${layer[1].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[1].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[1].city }}</div>
              </div>
            </div>
            <div
                v-if="layer[2]"
                class="project"
                @click="redirect(layer[2].name)"
            >
              <img
                  :alt="layer[2].title"
                  :src="`/assets/projects/${layer[2].name}/${layer[2].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[2].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[2].city }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="index % 2 == 1" class="sub right switched">
          <div
              v-if="layer[3]"
              class="project horizontal"
              @click="redirect(layer[3].name)"
          >
            <img
                :alt="layer[3].title"
                :src="`/assets/projects/${layer[3].name}/${layer[3].main_photo}`"
            />
            <div class="info">
              <div class="title">{{ layer[3].title }}</div>
              <div class="separator"></div>
              <div class="location">{{ layer[3].city }}</div>
            </div>
          </div>

          <div
              v-if="layer[4]"
              :class="['project', 'vertical', !(layer[5] || layer[6]) ? 'v-single' : '']"
              @click="redirect(layer[4].name)"
          >
            <img
                :alt="layer[4].title"
                :src="`/assets/projects/${layer[4].name}/${layer[4].main_photo}`"
            />
            <div class="info">
              <div class="title">{{ layer[4].title }}</div>
              <div class="separator"></div>
              <div class="location">{{ layer[4].city }}</div>
            </div>
          </div>

          <div
              v-if="layer[5] || layer[6]"
              :class="['normal', layer[5] && layer[6] ? 'double' : 'single']"
          >
            <div
                v-if="layer[5]"
                class="project"
                @click="redirect(layer[5].name)"
            >
              <img
                  :alt="layer[5].title"
                  :src="`/assets/projects/${layer[5].name}/${layer[5].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[5].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[5].city }}</div>
              </div>
            </div>
            <div
                v-if="layer[6]"
                class="project"
                @click="redirect(layer[6].name)"
            >
              <img
                  :alt="layer[6].title"
                  :src="`/assets/projects/${layer[6].name}/${layer[6].main_photo}`"
              />
              <div class="info">
                <div class="title">{{ layer[6].title }}</div>
                <div class="separator"></div>
                <div class="location">{{ layer[6].city }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div
          v-for="(projects_row, index) in getGridArray()"
          :key="index"
          class="projects_row"
      >
        <div
            v-for="(project, index2) in projects_row"
            :key="index2"
            class="project"
            @click="redirect(project.name)"
        >
          <img
              :alt="project.title"
              :src="`/assets/projects/${project.name}/${project.main_photo}`"
          />
          <div class="info">
            <div class="title">{{ project.title }}</div>
            <div class="separator"></div>
            <div class="location">{{ project.city }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useFadeInOnScroll } from '../../../../../Documents/GitHub/create-constructions/src/composables/useFadeInOnScroll';

export default defineComponent({
  name: 'ProjectsView',
  data() {
    return {
      projects: [] as any,
      loading: true,
    }
  },
  methods: {
    redirect(project_name: string) {
      window.location.href = `/project/${project_name}`;
    },
    getListArray() {
      const layers = [];

      for (let i = 0; i < this.projects.length; i += 7) {
        const projects = [];

        for (let j = i; j < i+7; j++) {
          projects.push(this.projects[j]);
        }

        layers.push(projects);
      }

      return layers;
    },
    getGridArray() {
      const projects_grid = [];
      const names = [];

      const projects = Object.values(this.projects);

      for (const n in this.projects) {
        names.push(n);
      }

      for (let i = 0; i < projects.length; i += 4) {
        const arr = [];

        for (let x = 0; x < 4; x++) {
          if (projects[i + x]) {
            arr.push({
              name: names[i + x],
              ...projects[i + x] as object
            });
          }
        }

        projects_grid.push(arr);
      }

      return projects_grid;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.projects = to.params.projects;
      vm.loading = false;
    });
  },
  watch: {
    '$route' (to, from) {
      this.projects = to.params.projects;
    }
  },
  setup() {
    useFadeInOnScroll();
  },
});
</script>

<style lang="scss" scoped>
.projects {
  padding-top: 10rem;

  .header {
    padding-left: 4rem;

    .pre-title {
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .title {
      margin-top: 1rem;
      font-size: 50px;
    }
  }

  .list {
    width: 100%;
    max-width: 1920px;
    display: flex;
    flex-wrap: wrap;
    margin: 2rem auto 0;

    .layer {
      display: flex;
      margin-top: 2rem;
      width: 100%;

      .sub {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-around;
        width: 50%;

        .project {
          width: 28rem;
          height: 28rem;
          cursor: pointer;
          transition: all 0.9s ease;

          img {
            position: absolute;
            z-index: -1;
            width: 28rem;
            height: 28rem;
            object-fit: cover;
            border-radius: 3px;
          }

          .info {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 25px;
            color: white;
            background-color: #00000063;
            opacity: 0;
            transition: all 0.4s ease;
            justify-content: center;
            border-radius: 3px;

            .separator {
              display: flex;
              justify-content: center;
              margin-top: 5px;
            }

            .separator::before {
              content: "";
              position: absolute;
              width: 50px;
              height: 4px;
              background-color: #ffffff54;
              border-radius: 15px;
            }

            .location {
              margin-top: 10px;
              font-size: 20px;
            }
          }
        }

        .normal {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 2rem;
        }

        .square + .single {
          width: 97%;
        }

        .horizontal {
          width: 58rem;
          height: 28rem;

          img {
            width: 58rem;
            height: 28rem;
          }
        }

        .vertical {
          height: 58rem;

          img {
            height: 58rem;
          }
        }

        .v-single {
          margin-right: 30rem;
        }

        .square {
          width: 58rem;
          height: 58rem;

          img {
            width: 58rem;
            height: 58rem;
          }
        }

        .square + .normal {
          margin-top: 2rem;
        }

        .vertical + .normal {
          margin-top: 2rem;
        }

        .vertical {
          margin-top: 2rem;
        }

        .project:hover .info {
          opacity: 1;
        }
      }

      .vertical + .normal {
        flex-direction: column;
      }
    }
  }

  .grid {
    width: 91%;
    margin: 2rem auto;
    flex-wrap: wrap;
    display: none;

    .projects_row {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .project {
        width: 25rem;
        height: 25rem;
        cursor: pointer;
        margin: 1rem 0;

        img {
          position: absolute;
          z-index: -1;
          width: 25rem;
          height: 25rem;
          object-fit: cover;
          border-radius: 3px;
        }

        .info {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 25px;
          color: white;
          background-color: #00000063;
          justify-content: center;
          border-radius: 3px;

          .separator {
            display: flex;
            justify-content: center;
            margin-top: 5px;
          }

          .separator::before {
            content: "";
            position: absolute;
            width: 50px;
            height: 4px;
            background-color: #ffffff54;
            border-radius: 15px;
          }

          .location {
            margin-top: 10px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1860px) {
  .projects {
    .list {
      max-width: 1570px !important;

      .square,
      .square img {
        width: 46rem !important;
        height: 46rem !important;
      }

      .horizontal,
      .horizontal img {
        width: 47rem !important;
        height: 22rem !important;
      }

      .vertical,
      .vertical img {
        height: 47rem !important;
        width: 23rem !important;
      }

      .v-single {
        margin-right: 24rem !important;
      }

      .normal {
        gap: 3rem !important;

        .project,
        .project img {
          width: 22rem !important;
          height: 22rem !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1520px) {
  .projects {
    .list {
      max-width: 1350px !important;

      .square,
      .square img {
        width: 39rem !important;
        height: 39rem !important;
      }

      .horizontal,
      .horizontal img {
        width: 40rem !important;
        height: 18rem !important;
      }

      .vertical,
      .vertical img {
        height: 40rem !important;
        width: 19rem !important;
      }

      .v-single {
        margin-right: 21rem !important;
      }

      .normal {
        gap: 2rem !important;

        .project,
        .project img {
          width: 19rem !important;
          height: 19rem !important;
        }
      }

      .square + .normal {
        //gap: 4rem !important;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .projects {
    .header {
      padding-left: 1rem !important;

      .pre-title {
        font-size: 13px !important;
      }

      .title {
        margin-top: 1rem;
        font-size: 35px !important;
      }
    }

    .list {
      display: none !important;
    }

    .grid {
      display: flex !important;

      .project {
        width: 22rem !important;
        height: 22rem !important;

        img {
          width: 22rem !important;
          height: 22rem !important;
        }
      }
    }
  }
}
</style>
