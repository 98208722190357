<template>
  <div class="project">
    <div class="header">
      <span class="title">{{ project.title }}</span>
      <span class="description">{{ project.description }}</span>
    </div>
    <div class="image-slider">
      <Carousel :items-to-show="1" :transition="700" :wrap-around="true">
        <Slide
            v-for="(image, index) in generateLoopArray(this.project.image_count)"
            :key="index"
        >
          <div class="carousel__item">
            <img
                :src="`/assets/projects/${this.$route.params.id}/image-${image}.webp`"
                alt="image"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
    <div class="info">
      <div class="separator"></div>
      <div class="list">
        <div class="data" v-if="project.design">
          <span class="title">Design</span
          ><span class="value">{{ project.design }}</span>
        </div>
        <div class="data" v-if="project.construction">
          <span class="title">Construction</span
          ><span class="value">{{ project.construction }}</span>
        </div>
        <div class="data" v-if="project.city">
          <span class="title">City</span
          ><span class="value">{{ project.city }}</span>
        </div>
        <div class="data" v-if="project.country">
          <span class="title">Country</span
          ><span class="value">{{ project.country }}</span>
        </div>
        <div class="data" v-if="project.client">
          <span class="title">Client</span
          ><span class="value">{{ project.client }}</span>
        </div>
        <div class="data" v-if="project.area">
          <span class="title">Space</span
          ><span class="value">{{ project.area }}m²</span>
        </div>
      </div>
      <div class="separator"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Carousel,Pagination, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'ProjectView',
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  data() {
    return {
      project: {} as any,
      loading: true,
    }
  },
  methods: {
    generateLoopArray(count: number): number[] {
      return Array.from({ length: count }, (_, i) => i + 1);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.project = to.params.project;
      vm.loading = false;
    });
  },
  watch: {
    '$route' (to, from) {
      this.project = to.params.project;
      // @ts-ignore
      this.$root.loading = false;
    }
  },
});
</script>

<style lang="scss" scoped>
.project {
  padding-top: 7rem;

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 35px;
    }

    .description {
      width: 65%;
      margin-top: 1rem;
      font-size: 17px;
    }
  }

  .image-slider {
    width: 65%;
    justify-content: center;
    margin: 2rem auto 0;

    img {
      width: 60rem;
      height: 30rem;
      object-fit: cover;
      user-select: none;
    }
  }

  .info {
    display: flex;
    width: 80rem;
    margin: auto;
    height: 23rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .separator {
      margin: 2rem 10rem 2rem 0;
    }

    .separator::before {
      content: " ";
      position: absolute;
      width: 10rem;
      height: 3px;
      background-color: #63636340;
      border-radius: 15px;
    }

    .list {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-content: space-around;

      .data {
        width: 26%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 18px;
          margin-bottom: 5px;
        }

        .value {
          color: #3c3c3c;
        }
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .project {
    .image-slider img {
      width: 50rem !important;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .project {
    .image-slider img {
      width: 40rem !important;
    }
  }
}

@media only screen and (max-width: 750px) {
  .project {
    .image-slider img {
      width: 30rem !important;
    }
  }
}

@media only screen and (max-width: 570px) {
  .project {
    .image-slider img {
      width: 20rem !important;
    }
  }
}

@media only screen and (max-width: 380px) {
  .project {
    .image-slider img {
      width: 15rem !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .project {
    .description {
      width: 85% !important;
      font-size: 16px !important;
      text-align: justify;
    }

    .image-slider {
      width: 85% !important;
    }

    .info {
      margin: 4rem auto;
      width: 90%;

      .data {
        width: 50% !important;
        margin-bottom: 2rem;

        .value {
          text-align: center;
        }
      }
    }
  }
}
</style>
