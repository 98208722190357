import { onMounted } from 'vue';

export function useFadeInOnScroll(selector: string = '.fos') {
    onMounted(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    (entry.target as HTMLElement).classList.add('visible');
                }
            });
        });

        const sectionElements = document.querySelectorAll(selector);
        sectionElements.forEach((section) => {
            observer.observe(section);
        });
    });
}
