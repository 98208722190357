<template>
  <div class="contact">
    <div class="sections">
      <div class="contact-info">
        <span class="title">Contact Us</span>
        <div class="list">
          <div>
            <font-awesome-icon icon="at" /><a
              :href="`mailto:${info.email}`"
          >{{ info.email }}</a
          >
          </div>
          <div>
            <font-awesome-icon icon="mobile-screen" /><a
              :href="`tel:${info.telephone}`"
          >{{ info.telephone }}</a
          >
          </div>
          <div>
            <font-awesome-icon icon="location-dot" /><a
              :href="info.map"
          >{{ info.location }}</a
          >
          </div>
        </div>
        <div class="map">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10992.00670463291!2d25.753895491634236!3d35.008128316825776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14907960a491a821%3A0x4582558d143cdb4f!2sEpar.Od.%20Ierapetras%20-%20Sitias%2075%2C%20Ierapetra%20722%2000!5e0!3m2!1sen!2sgr!4v1719055791659!5m2!1sen!2sgr"
              width="400"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div class="contact-form">
        <div class="form">
          <div class="status">{{ form.status_message }}</div>
          <div class="name">
            <span>Full Name</span>
            <input type="text" v-model="form.name" required />
          </div>
          <div class="email">
            <span>Email</span>
            <input
                type="email"
                pattern=".+@example\.com"
                size="30"
                v-model="form.email"
                required
            />
          </div>
          <div class="telephone">
            <span>Telephone</span>
            <input type="tel" size="10" v-model="form.telephone" required />
          </div>
          <div class="message">
            <span>Message</span>
            <textarea v-model="form.message"></textarea>
          </div>
        </div>
        <div class="submit-button" @click="sendForm">Submit</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'ContactView',
  props: [
    'info'
  ],
  data() {
    return {
      form: {
        status_message: '',
        name: '',
        email: '',
        telephone: '',
        message: ''
      }
    };
  },
  methods: {
    redirect(link: string) {
      window.location.href = link;
    },
    async sendForm() {
      const req = await axios({
        url: 'https://createconstructions.gr/assets/php/form',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          name: this.form.name,
          email: this.form.email,
          telephone: this.form.telephone,
          message: this.form.message
        }
      }).catch((e: any) => {console.error(e)});

      if(req && req.status === 429) {
        this.form.status_message = 'You have been rate limited, please try again later!';
        return;
      }

      if(!req || req.status !== 200) {
        this.form.status_message = 'Something went wrong, please try again later!';
        return;
      }

      this.form = {
        status_message: 'Thank you for reaching out! We\'ve received your message and will be in touch soon.',
        name: '',
        email: '',
        telephone: '',
        message: ''
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.contact {
  padding-top: 10rem;

  .sections {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .contact-info {
      .title {
        font-size: 40px;
      }

      .list {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 18px;

        a {
          color: black;
        }

        div {
          margin-bottom: 10px;

          svg {
            margin-right: 5px;
          }
        }
      }

      .map {
        margin-top: 2rem;
      }
    }

    .contact-form {
      box-shadow: 0px 0px 1.4em #929292;
      padding: 0 2rem 0 2rem;
      border-radius: 2px;
      width: 30rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .form {
        .name,
        .email,
        .telephone,
        .message {
          display: flex;
          flex-direction: column;

          span {
            font-size: 16px;
          }

          input,
          textarea {
            border: none;
            border-bottom: 1px solid #f0584d;
            background-color: #e7e7e7;
            border-radius: 5px;
            margin: 5px 0 1rem 0;
            border-bottom: 1px solid red;
          }

          input:focus,
          textarea:focus {
            outline: none;
          }

          input {
            height: 40px;
            padding: 0 10px;
          }

          textarea {
            resize: none;
            height: 65px;
            padding: 10px;
          }
        }
      }

      .submit-button {
        width: 60px;
        padding: 0.8rem 1rem;
        background-color: #ffffff;
        color: #f0584d;
        border: 1px solid #f0584d;
        border-radius: 5px;
        transition: all 0.4s ease;
        cursor: pointer;
        text-align: center;
      }

      .submit-button:hover {
        background-color: #f0584d;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .contact {
    .sections {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contact-form {
        margin: 2rem 0;
        width: 21rem;
        padding: 1rem 2rem 1rem 2rem;
      }
    }
  }
}
</style>
