<template>
  <div class="services">
    <div class="header">
      <div class="pre-title">Our Services</div>
      <div class="title">
        We Offer a Range of<br />Services to Meet Your Needs
      </div>
    </div>
    <div class="services-list fos">
      <div class="list">
        <div class="service">
          <font-awesome-icon icon="fa-solid fa-helmet-safety" />
          <span class="title">Construction</span>
          <span class="description"
          >We provide top-quality construction services for residential and
            commercial projects, focusing on safety, efficiency, and durability.
            Our experienced team ensures timely and budget-friendly
            completion.</span
          >
        </div>
        <div class="service">
          <font-awesome-icon icon="fa-solid fa-pen-ruler" />
          <span class="title">Architectural Design</span>
          <span class="description"
          >Our architects create innovative and functional designs tailored to
            your vision. We work closely with you to ensure every detail is both
            aesthetically pleasing and practical.</span
          >
        </div>
        <div class="service">
          <font-awesome-icon icon="fa-solid fa-paste" />
          <span class="title">Licensing</span>
          <span class="description"
          >We handle all building permits and licenses, ensuring compliance
            with local regulations. Our expertise streamlines the process,
            allowing you to focus on your project without bureaucratic
            delays.</span
          >
        </div>
        <div class="service">
          <font-awesome-icon icon="fa-solid fa-user-tie" />
          <span class="title">Property Management</span>
          <span class="description"
          >Our property management services include tenant screening, rent
            collection, and maintenance. We maximize your investment's potential
            while providing tenants with a high-quality living experience.</span
          >
        </div>
        <div class="service">
          <font-awesome-icon icon="fa-solid fa-sign-hanging" />
          <span class="title">Real Estate</span>
          <span class="description"
          >Our expert agents guide you through buying, selling, or investing
            in properties. We offer personalized service and market insights to
            help you make informed decisions and find the perfect
            property.</span
          >
        </div>
      </div>
      <img src="/assets/images/services.webp" alt="services" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import { useFadeInOnScroll } from '../../../../../Documents/GitHub/create-constructions/src/composables/useFadeInOnScroll';

export default defineComponent({
  name: 'ServicesView',
  methods: {
    redirect(project_name: string) {
      window.location.href = `/project/${project_name}`;
    }
  },
  setup() {
    useFadeInOnScroll();
  },
});
</script>

<style lang="scss" scoped>
.services {
  padding-top: 10rem;

  .header {
    padding-left: 4rem;

    .pre-title {
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .title {
      margin-top: 1rem;
      font-size: 50px;
    }
  }

  .services-list {
    margin: 5rem auto 0;
    display: flex;
    max-width: 1920px;

    .list {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid black;
      border-left: 1px solid black;
      width: 75%;

      .service {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        display: flex;
        width: 35%;
        flex-direction: column;
        align-items: flex-start;
        padding: 4rem 6rem;

        svg {
          font-size: 2rem;
        }

        .title {
          font-weight: 600;
          text-transform: uppercase;
          margin-top: 1rem;
        }

        .description {
          margin-top: 1rem;
        }
      }
    }

    img {
      width: 38rem;
      height: 66rem;
      padding: 2rem;
      border: 1px solid black;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .services {
    .header {
      padding-left: 1rem !important;

      .pre-title {
        font-size: 13px !important;
      }

      .title {
        margin-top: 1rem;
        font-size: 35px !important;
      }
    }

    .list {
      width: 100% !important;

      .service {
        width: 100% !important;
        align-items: center !important;
        padding: 4rem 2rem !important;

        .description {
          text-align: center;
        }
      }
    }

    img {
      display: none !important;
    }
  }
}
</style>
